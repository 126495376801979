// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-coc-page-tsx": () => import("./../src/templates/coc-page.tsx" /* webpackChunkName: "component---src-templates-coc-page-tsx" */),
  "component---src-templates-events-page-tsx": () => import("./../src/templates/events-page.tsx" /* webpackChunkName: "component---src-templates-events-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-resources-page-tsx": () => import("./../src/templates/resources-page.tsx" /* webpackChunkName: "component---src-templates-resources-page-tsx" */)
}

